const SERVER_URL =
  "https://amberwoodre-backend-nodejs-cpmn6gngia-wl.a.run.app/";
// "http://192.168.29.179:8080/"
const IMAGE_URL = "https://storage.googleapis.com/cdn-amberwoodre/";
const AGENT_ID = "64e4f302a5e317e75dd6f9f2";
module.exports = {
  SERVER_URL,
  IMAGE_URL,
  AGENT_ID,
};
